
import { useRef, useEffect } from "react";
import { Subscription, TimeRange } from "../redux/threats-server/types";
import { ACTIVITY_INSIGHTS_TIMERANGE_MAPPINGS, CHART_REDIRECT_ROUTES,
    IncidentPageType, NGFW, NGFW_TIMERANGE_MAPPINGS,
    PRISMA_ACCESS, RECORD_DURATIONS, AppTypeValueMappingForActivityInsights,
    AppMappingForActivityInsights,ThreatMapping, ThreatAppTypeValueMapping,
    DASHBOARDS,
    SECURITY_SERVICE_TYPE,
    platformTypeMap,
    parentMap,
    SourceValueMappingForActivityInsights,
    SegmentMappingForActivityInsights,
    NGFWDeviceHealthScoreToGradeMapping,
    BYTES_CONVERSION_FACTOR,
    platformTypeLicenseMap,
    ademLicenseContentTypes,
    ademLicenseMap,
    THREAT_ACTIONS,
    AspectRatioDefinition,
    SCREEN_WIDTH_L,
    SCREEN_WIDTH_XL,
    SCREEN_WIDTH_XXL,
    SCREEN_WIDTH_XXXL,
    AppSubTypeValueMappingForActivityInsights,
} from "./NetsecUIConstants";
import useRecordDuration from "./hooks/useRecordDuration";
import * as d3 from "d3";
import { PercentageIncDecType } from "../redux/summary-server/types";
import { TrendType } from "../redux/datasecurity-server/types";
import { startCase, toLower } from "lodash";
import { TrafficProcessedByFilter } from "../redux/filter-server/types";
import moment from "moment";
import { getState } from "@sparky/framework";
import { formatIntegersWithD3Fn } from "./i18n/translate";
import IntlUtil from "./i18n/IntlUtil";
import { isAppTaggingAvailable } from "../components/NetsecUIChart/NetsecUILicenseUtil";
import {
    APP_SUB_TYPE_NODE_TYPE,
    APP_TYPE_NODE_TYPE,
    SOURCE_NODE_TYPE,
    SUBSCRIPTION_NODE_TYPE
} from "../components/NetsecUIChart/NetsecUIChartTypes";
import { convertTimeRangeToEpoch } from "../redux/reduxUtils";

export const getNonEnabledSubscriptions = (subscriptions: Subscription[]): Subscription[] =>
    subscriptions?.filter((subscription: Subscription) => !subscription.ENABLED || !subscription.LICENSED);

export const getEnabledSubscriptions = (subscriptions: Subscription[]): Subscription[] =>
    subscriptions?.filter((subscription: Subscription) => subscription.ENABLED && subscription.LICENSED);

export const convertDisplayNameToKey = (name: string) => name?.toLowerCase().replace(/\s/g, "_");

export const FooterRecordDuration = (id: string, comment: string) => {
    const { recordStartTime, logDuration } = useRecordDuration(id, RECORD_DURATIONS);
    const cardRef = useRef(null);
    useEffect(() => {
        recordStartTime?.();
    }, []);


    if(cardRef.current) {
        logDuration?.(comment);
    }
    return cardRef;
}

export const getTrendData = (props): PercentageIncDecType => {
    const { previousValue, currentValue } = props;

    const delta = currentValue - previousValue;
    const isTrendUp = delta >= 0;
    const percentage = (isNil(previousValue) || isNil(currentValue)) ?
        null : ((previousValue === 0 && currentValue > 0)? 100 : Math.round((Math.abs(delta / previousValue)) * 100));

    return {
        delta,
        percentage: isFinite(percentage) ? percentage: null,
        isTrendUp
    }
}

export const formatTime = (timeRange: TimeRange) =>
    `${timeRange.match(/\d+/)} ${timeRange.match(/[A-Z]+/)?.[0]?.toLowerCase()}`;

export const getKeyByValue = (object, value) =>
    Object.keys(object).find(key => object[key] === value);

export const replaceSpaces = (name: string) =>
    name?.split(" ")?.join("_");

export const replaceSpacesWithDashes = (name: string) =>
    name?.split(" ")?.join("-");

export const formatNumber = (val) => !val || val === 0? 0 : val === "-"? val : d3.format(".3~s")(val);
export const netsecUIBytesFormat = (val) => bytesFormat(val, BYTES_CONVERSION_FACTOR);
export const formatPercentage = (d) => `${d3.format(".2~s")(d).toUpperCase()}%`;
export const unitFormatter = (v) => v? formatIntegersWithD3Fn(v) : "0";

export const dateTimeFormatter = (utcTimeInMs: number, preposition = ""): string => {
    const intlInstance = IntlUtil.getInstance();
    return formatToLocalMonthDayYearTimeWithPreposition(utcTimeInMs, preposition, intlInstance);
}

export const parseStringWithKeys = (str: string, valuesToReplace: Record<string, any>) => {
    if(valuesToReplace) {
        Object.keys(valuesToReplace).forEach((k) => str = str.replace(new RegExp(`{${k}}`, "g"), valuesToReplace[k]));
    }
    return str;
}

export const sortAscByFirstArrayElement = (a,b) => {
    return a[0] === b[0]? 0 : a[0] > b[0]? 1 : -1
}

export const mapPercentageToTrend = (percentage: number) => (
    {
        percentage: Math.abs(percentage),
        isTrendUp: percentage >= 0
    }
);

export const mapToLineChartData = (data: TrendType[], totalField: string) => {
    return d3.flatRollup(
        data || [],
        (v) => d3.sum(v,
            (d) => d?.[totalField] || 0),
        (d) => d?.time_stamp
    ).sort(sortAscByFirstArrayElement);
};

type FeatureType = {
    NAME: string
}

type AppConfig = {
    appType: FeatureType,
    APP: string
}

export const handleNGFWRedirections = (actions, getState, props) => {
    const { timeRange, subCategoryDisplayName, viewType, severity } = props;
    // NETVIS-798 Use ALL instead of timerange
    const customRange = NGFW_TIMERANGE_MAPPINGS["ALL"]?.();
    const timeRangeFilter = timeRange !== undefined? {
        dateRange: [
            customRange
        ]
    } : {};
    const subCategory = subCategoryDisplayName !== undefined? {
        subCategory: [{ label: subCategoryDisplayName, value: subCategoryDisplayName }]
    } : {};
    const severityFilterOption = severity !== undefined? {
        severity: [{ label: severity, value: severity }]
    } : {};
    const category = {
        category: [{ label: "Health", value: "Health" }]
    };
    const status = {
        status: [
            { label: "New", value: "New" },
            { label: "Assigned", value: "Assigned" },
            { label: "In Progress", value: "In Progress" }
        ]
    }
    const ngfwState = getState()?.aiopsForNGFWMainState ?? {};
    actions.setValues({
        aiopsForNGFWMainState: {
            ...ngfwState,
            incidentFilters: {
                [`incidentsAndAlerts.alerts.${viewType}.filters`]: {
                    ...timeRangeFilter,
                    ...subCategory,
                    ...category,
                    ...severityFilterOption,
                    ...status
                }
            }
        }
    });
}

export const titleCase = (str) => startCase(toLower(str));

type ThreatsRedirectionProps = {
    appType?: FeatureType,
    appSubType?: FeatureType,
    source?: FeatureType,
    subscription?: FeatureType,
    trafficProcessedBy: string,
    timeRange?: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    history: any,
    threatCategoryName?: string,
    dashBoard?: string,
    threatAction?: "Alerted" | "Blocked",
    redirect?: boolean,
    severity?: string,
    isGenAIOnly?: boolean
}
export const handleThreatsRedirection = (props: ThreatsRedirectionProps): string => {
    const { appType, appSubType, isGenAIOnly, source, subscription, trafficProcessedBy, timeRange,
        history, threatCategoryName, threatAction, severity, redirect } = props;
    const appTypeName = appType?.NAME;
    const subscriptionName = subscription?.NAME;
    const sourceName = source?.NAME;

    const args = [];
    if(!isNil(threatCategoryName)) { // Threat Category Name filter
        const filterPropName = ThreatMapping["threatCategoryName"];
        // Threats page accepts lowercase names for category
        args.push(`${filterPropName}=${threatCategoryName.toLowerCase()}`);
    }
    //platform type(NGFW/PA) from funnel filter
    if(!isNil(trafficProcessedBy) && trafficProcessedBy !== TrafficProcessedByFilter.ALL) { // trafficProcessedBy filter
        const filterPropName = ThreatMapping["trafficProcessedBy"];
        args.push(`${filterPropName}=${trafficProcessedBy.toUpperCase()}`);
    }
    if(!isNil(sourceName)) { // Source filter
        const filterPropName = ThreatMapping["sourceName"];
        const sourceTypeFilterMapValue = getKeyByValue(parentMap, sourceName);
        args.push(`${filterPropName}=${sourceTypeFilterMapValue}`);
    }
    //platform type(NGFW/PA) or licenses from central node
    if(!isNil(subscriptionName)) { // Subscription filter
        let filterPropName = "";
        if(props?.dashBoard === DASHBOARDS.THREATS) {
            filterPropName = ThreatMapping["subscriptionThreatName"];
            const filterValue = getKeyByValue(SECURITY_SERVICE_TYPE, subscriptionName);
            args.push(`${filterPropName}=${filterValue}`);
        } else {
            filterPropName = ThreatMapping["subscriptionSummaryName"];
            const filterMapValue = getKeyByValue(platformTypeMap, subscriptionName)?.toUpperCase();
            args.push(`${filterPropName}=${filterMapValue}`);
        }
    }
    if(!isNil(appTypeName)) { // Application Type filter
        const filterPropName = ThreatMapping["appTypeName"];
        const appTypeFilterMapValue = ThreatAppTypeValueMapping[appTypeName]
        args.push(`${filterPropName}=${appTypeFilterMapValue}`);
    }

    if(!isNil(timeRange)) { // Time filter
        const filterPropName = ThreatMapping["timeRange"];
        args.push(`${filterPropName}=${ACTIVITY_INSIGHTS_TIMERANGE_MAPPINGS[timeRange]}`);
    }

    if(!isNil(threatAction)) {
        args.push(`una_threat_actions=${threatAction === THREAT_ACTIONS.Blocked? "TRUE" : "FALSE"}`);
    }

    if(!isNil(severity)) {
        args.push(`una_threat_severity=${severity}`);
    } else {
        args.push("una_threat_severity=Critical,High,Medium,Low");
    }
    if(!isNil(appSubType)) {
        args.push(`una_app_tags=${AppSubTypeValueMappingForActivityInsights[appSubType.NAME]}`);
    }
    // no support for gen ai = FALSE in CC
    if(isGenAIOnly) {
        args.push("una_gen_ai=TRUE");
    }

    if(redirect === true) {
        history.push(`${CHART_REDIRECT_ROUTES().ACTIVITY_INSIGHTS_THREATS_ROUTE}${isEmpty(args)? "" : `?${args.join("&")}`}`)
    } else {
        return `${CHART_REDIRECT_ROUTES().ACTIVITY_INSIGHTS_THREATS_ROUTE}${isEmpty(args)? "" : `?${args.join("&")}`}`
    }
};

export const handleIncidentsRedirection = (
    props: { scopeName: TrafficProcessedByFilter,
        subCategory?: string,
        subCategoryDisplayName?: string, timeRange, severity? }, pageType, history, actions, getState, redirect) => {
    const { scopeName, subCategory, subCategoryDisplayName, timeRange, severity } = props;
    const { INCIDENTS_ALERTS_NGFW_ROUTE, INCIDENTS_ALERTS_PRISMA_ACCESS_ROUTE } = CHART_REDIRECT_ROUTES();
    if(scopeName === NGFW) {
        const viewType = pageType === IncidentPageType.LIST_VIEW? "listview" : "overview";
        if(redirect === true) {
            handleNGFWRedirections(actions, getState, { subCategoryDisplayName, timeRange, viewType, severity });
            // TODO update to incidents once NGFW incidents release happens
            history.push(`${INCIDENTS_ALERTS_NGFW_ROUTE}/${viewType}`)
        } else {
            return `${INCIDENTS_ALERTS_NGFW_ROUTE}/${viewType}`
        }
    } else if(scopeName === PRISMA_ACCESS) {
        // TODO confirm filters for pa AIOPS-9002
        const viewType = pageType === IncidentPageType.LIST_VIEW? "incident_list" : "incalerts_overview";
        let args = "";

        if(subCategory?.includes(",")) {
            args = subCategory.split(",")
                .map((singleSubCategory, i) =>
                    `${i === 0? "?" : "&"}incidentCategory[${i}]=${singleSubCategory?.trim() ?? ""}`).join("");
        } else {
            args = `?incidentCategory[0]=${subCategory ?? ""}`;
            // NETVIS-871 the subcategory MU is actually counting MU and GP Prisma Access incidents
            if(subCategory === "GP" && subCategoryDisplayName === "Mobile Users") {
                args += "&incidentCategory[1]=MU";
            }
        }
        args += `&severity=${severity ?? ""}`;

        if(redirect === true) {
            history.push(`${INCIDENTS_ALERTS_PRISMA_ACCESS_ROUTE}/${viewType}${args}`)
        } else {
            return `${INCIDENTS_ALERTS_PRISMA_ACCESS_ROUTE}/${viewType}${args}`
        }
    }
};

type AppDetailsRedirectionProps = {
    trafficProcessedBy: TrafficProcessedByFilter,
    appConfig: AppConfig & {
        SCORE: number
    },
    timeRange: TimeRange,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    history: any,
    subscription: FeatureType,
    source?: FeatureType,
    dashBoard?: string,
    severity?: string,
    appType?: FeatureType,
    appSubType?: FeatureType,
    isGenAIOnly?: boolean // funnel filter
    widgetName?: string
}
export const handleAppDetailsRedirection = (props: AppDetailsRedirectionProps):void => {
    const { trafficProcessedBy, appConfig, timeRange, history, subscription,
        source, severity, appType, appSubType, isGenAIOnly, widgetName } = props;
    const { ACTIVITY_INSIGHTS_APP_DETAILS_ROUTE,
        ACTIVITY_INSIGHTS_APPS_LIST_ROUTE,
        ACTIVITY_INSIGHTS_APP_EXPERIENCE_ROUTE } = CHART_REDIRECT_ROUTES();
    const appTypeName = appConfig?.appType?.NAME;
    const subscriptionName = subscription?.NAME;
    const sourceName = source?.NAME;
    const appSubTypeKey = AppSubTypeValueMappingForActivityInsights[appConfig?.appType?.NAME];

    const args = [];

    // No redirect available for data security
    if(props?.dashBoard === DASHBOARDS.DATA_SECURITY) {
        return;
    }

    if(props?.dashBoard === DASHBOARDS.THREATS) {
        args.push("alertedApps=True");
    }

    if(!isNil(trafficProcessedBy) && trafficProcessedBy !== TrafficProcessedByFilter.ALL) { // trafficProcessedBy filter
        const filterPropName = AppMappingForActivityInsights["trafficProcessedBy"];
        args.push(`${filterPropName}=${trafficProcessedBy.toUpperCase()}`);
    }
    if(!isNil(timeRange)) { // Time filter
        const filterPropName = AppMappingForActivityInsights["timeRange"];
        args.push(`${filterPropName}=${ACTIVITY_INSIGHTS_TIMERANGE_MAPPINGS[timeRange]}`);
    }

    if(!isNil(appTypeName)) { // Application Type filter
        const filterPropName = AppMappingForActivityInsights["appTypeName"];
        const appTypeFilterMapValue = AppTypeValueMappingForActivityInsights[appTypeName] ??
            AppTypeValueMappingForActivityInsights[appType?.NAME];
        args.push(`${filterPropName}=${appTypeFilterMapValue}`);
    }

    if(!isNil(appSubType) || appSubTypeKey) {
        const filterPropName = AppMappingForActivityInsights["appSubTypeName"];
        const appSubTypeFilterMapValue = AppSubTypeValueMappingForActivityInsights[appSubType?.NAME] ?? appSubTypeKey;
        args.push(`${filterPropName}=${appSubTypeFilterMapValue}`);
    }

    if(!isNil(severity)) {
        args.push(`una_threat_severity=${severity}`);
    } else {
        args.push("una_threat_severity=Critical,High,Medium,Low");
    }

    //platform type(NGFW/PA) or licenses from central node
    if(!isNil(subscriptionName)) { // Subscription filter
        if(props?.dashBoard === DASHBOARDS.THREATS) {
            const filterPropName = AppMappingForActivityInsights["securityServiceType"];
            args.push(`${filterPropName}=${subscriptionName}`);
        } else {
            const filterPropName = AppMappingForActivityInsights["subscriptionName"];
            const filterMapValue =  getKeyByValue(platformTypeMap, subscriptionName)?.toUpperCase();
            args.push(`${filterPropName}=${filterMapValue}`);
        }
    }

    if(!isNil(sourceName)) { // Source filter
        const filterPropName = AppMappingForActivityInsights["sourceName"];
        const filterValue = SourceValueMappingForActivityInsights[sourceName]
        args.push(`${filterPropName}=${filterValue}`);
    }

    if(isGenAIOnly || widgetName === "TopGenAIUseCasesByUsers") {
        const filterPropName = AppMappingForActivityInsights["isGenAIOnly"];
        args.push(`${filterPropName}=TRUE`);
    }

    // Application name is not available,
    // or Application name maps to multiple applications example "& 27 more",
    // redirect to applications list page
    if(isNil(appConfig?.APP) ||  appConfig?.APP.indexOf(" more") > -1) {
        history?.push(`${ACTIVITY_INSIGHTS_APPS_LIST_ROUTE}${isEmpty(args)? "" : `?${args.join("&")}`}`);
    }
    // If app score is available redirect to App details experience page else redirect to application
    // details activity page
    else if(!isNil(props.appConfig.SCORE)) {
        args.push(`appName=${appConfig.APP}`);
        history?.push(`${ACTIVITY_INSIGHTS_APP_EXPERIENCE_ROUTE}${isEmpty(args)? "" : `?${args.join("&")}`}`);
    } else { // Application name is available, redirect to application details page.
        args.push(`appName=${appConfig.APP}`);
        history?.push(`${ACTIVITY_INSIGHTS_APP_DETAILS_ROUTE}${isEmpty(args)? "" : `?${args.join("&")}`}`);
    }
}

export const handleAppExperienceScoreRedirection = (props) => {
    const { url, timeRange, subscriptionName = "Prisma Access", segment, score, history } = props;
    const args = [];
    if(!isNil(timeRange)) { // Time filter
        const filterPropName = AppMappingForActivityInsights["timeRange"];
        args.push(`${filterPropName}=${ACTIVITY_INSIGHTS_TIMERANGE_MAPPINGS[timeRange]}`);
    }
    //platform type from central node (NGFW/PA)
    if(!isNil(subscriptionName)) {
        const filterPropName = AppMappingForActivityInsights["subscriptionName"];
        const filterMapValue =  getKeyByValue(platformTypeMap, subscriptionName)?.toUpperCase();
        args.push(`${filterPropName}=${filterMapValue}`);
    }
    // experience score for different segments ie. good, fair or poor
    if(!isNil(segment) && !isNil(score)) {
        const filterPropName = SegmentMappingForActivityInsights[segment];
        args.push(`${filterPropName}=${score.join(",")}`);
    }
    history?.push(`${url}${isEmpty(args)? "" : (url.includes("?") as boolean) ?
        `&${args.join("&")}` : `?${args.join("&")}`}`);
};

export const constructSDWANNode = (siteCount?: number) =>
    [
        {
            NAME: "Prisma SD-WAN",
            VALUE: `${formatNumber(siteCount ?? 0)} ION Devices`
        }
    ];

export const getGrade = (score: number) =>
    Object.keys(NGFWDeviceHealthScoreToGradeMapping).find(grade =>
        score >= NGFWDeviceHealthScoreToGradeMapping[grade].min &&
            score <= NGFWDeviceHealthScoreToGradeMapping[grade].max);

export const getNGFWStartEndTime = (preset: TimeRange) => {
    const endTime:number = moment().unix();
    let startTime;
    if (preset === TimeRange.HOURS24) {
        startTime = moment.unix(endTime).subtract(24, "h").unix();
    }
    if (preset === TimeRange.DAYS7) {
        startTime = moment.unix(endTime).subtract(7, "d").unix();
    }
    if (preset === TimeRange.DAYS30) {
        startTime = moment.unix(endTime).subtract(30, "d").unix();
    }
    if (preset === TimeRange.HOURS1) {
        startTime = moment.unix(endTime).subtract(1, "h").unix();
    }
    if (preset === TimeRange.HOURS3) {
        startTime = moment.unix(endTime).subtract(3, "h").unix();
    }
    return [startTime, endTime];
}

const b64DecodeUnicode = (str) => {
    return decodeURIComponent(
        atob(str).replace(/(.)/g, (m, p) => {
            let code = p.charCodeAt(0).toString(16).toUpperCase();
            if (code.length < 2) {
                code = `0${code}`;
            }
            return `%${code}`;
        })
    );
};

const decode = (str) => {
    let output = str.replace(/-/g, "+").replace(/_/g, "/");
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += "==";
        break;
      case 3:
        output += "=";
        break;
      default:
        throw new Error("Illegal base64url string!");
    }

    try {
        return b64DecodeUnicode(output);
    } catch (err) {
        return atob(output);
    }
};

export const jwt_decode = (token, options = {}) => {
    if (typeof token !== "string") {
        console.log("Invalid token specified");
        return { iat: 0, exp: 0 };
    }

    options = options ?? {};
    const pos = options["header"] && options["header"] === true ? 0 : 1;
    try {
        return JSON.parse(decode(token.split(".")[pos]));
    } catch (e) {
        console.log(`Invalid token specified: ${e.message}`);
        return { iat: 0, exp: 0 };
    }
};

export const getAspectRatio = (width: number) => {
    if ( width >= SCREEN_WIDTH_XXXL ) {
        return AspectRatioDefinition.SCREEN_WIDTH_XXXL;
    } else if (width >= SCREEN_WIDTH_XXL) {
        return AspectRatioDefinition.SCREEN_WIDTH_XXL;
    } else if (width >= SCREEN_WIDTH_XL) {
        return AspectRatioDefinition.SCREEN_WIDTH_XL;
    } else if (width >= SCREEN_WIDTH_L) {
        return AspectRatioDefinition.SCREEN_WIDTH_L;
    }
    return AspectRatioDefinition.SCREEN_WIDTH_STANDARD;
  }


export const getADEMLicenseForNGFWPA = (subscriptionName: string, paLicenseInfo) =>
    getKeyByValue(platformTypeMap, subscriptionName) === NGFW?
        false : (
            !isNil(paLicenseInfo)? !(paLicenseInfo?.licenseExpired as boolean) : true
        )


export const getADEMLicensebyContentType = (contentType : ademLicenseContentTypes) => {
    const { cosmosFeatures } = getState()?.saseIAMainState ?? {};
    return hasRole(cosmosFeatures as Feature[], ademLicenseMap[contentType])
}

export const getLicenseForNGFWPA = (subscriptionName: string) => {
    const { cosmosFeatures } = getState()?.saseIAMainState ?? {};
    const featureName =  getKeyByValue(platformTypeMap, subscriptionName)
    if(isNil(featureName)) {
        return false;
    }
    if(subscriptionName === platformTypeMap.ngfw || subscriptionName === platformTypeMap.prisma_access) {
        return hasRole(cosmosFeatures as Feature[], platformTypeLicenseMap[featureName])
    } else if(subscriptionName === platformTypeMap.all) { // both ngfw and  case
        const ngfwAccess = hasRole(cosmosFeatures as Feature[], platformTypeLicenseMap["ngfw"])
        const paAccess = hasRole(cosmosFeatures as Feature[], platformTypeLicenseMap["prisma_access"])
        return ngfwAccess && paAccess
    }
}

export const getBaseName = () =>
    window.location.pathname.startsWith("/aiops-free")? "/aiops-free" : ""

export const linkDataIsAvailableForSubscription = (linksData, subscriptionName) => {
    return linksData?.findIndex(linkData => linkData?.SOURCE === subscriptionName) > -1
}
export const sourceDataIsAvailableForSubscription = (sourcesData, subscriptionName) => {
    const platformType =  getKeyByValue(platformTypeMap, subscriptionName)
    return sourcesData?.findIndex(sourceData => sourceData?.SOURCE === platformType) > -1
}
export const appTypeDataIsAvailableForSubscription = (appTypesData, subscriptionName) => {
    const platformType =  getKeyByValue(platformTypeMap, subscriptionName)
    return appTypesData?.findIndex(appTypeData => appTypeData?.platform_type === platformType) > -1
}

export const filterSubscriptionsByLicenseSubscriptionFilterAndAPIData = (
    subscriptions, linkData, sourcesData, appTypesData, subscriptionFilter, appSubTypeFilter, isGenAIOnly) => {
    return subscriptions?.filter(subscription => {
        if(getLicenseForNGFWPA(subscription.NAME)
            || linkDataIsAvailableForSubscription(linkData, subscription.NAME)
            || sourceDataIsAvailableForSubscription(sourcesData, subscription.NAME)
            || appTypeDataIsAvailableForSubscription(appTypesData, subscription.NAME)
        ) {
            return !isNil(subscriptionFilter?.NAME)? subscriptionFilter.NAME === subscription.NAME: true;
        }
        return false;
    });
}

export const filterSubscriptionsByLicenseAndAPIData = (subscriptions, linkData, sourcesData, appTypesData) => {
    return subscriptions?.filter(subscription => {
        return getLicenseForNGFWPA(subscription.NAME)
            || linkDataIsAvailableForSubscription(linkData, subscription.NAME)
            || sourceDataIsAvailableForSubscription(sourcesData, subscription.NAME)
            || appTypeDataIsAvailableForSubscription(appTypesData, subscription.NAME) ? true: false;
    });
}

export const SASE_TimeZoneNameFormatOptions = {
    timeZoneName: "short"
};

export const SASE_TimeStyleFormatOptions = {
    timeStyle: "short"
};

export const SASE_Year_FormatOptions = {
    year: "numeric"
};

export const SASE_Month_FormatOptions = {
    month: "short"
};
export const SASE_Month_Num_FormatOptions = {
    month: "2-digit"
};

export const SASE_Day_FormatOptions = {
    day: "2-digit"
};

/**
 * coverts timestamp into a high level date time string with optional preposition
 * @param utcTimeInMs
 * @param preposition
 * @returns
 */
export function formatToLocalMonthDayYearTimeWithPreposition(utcTimeInMs: number, preposition, intlUtil): string {
    if (isNil(utcTimeInMs)) {
        return null;
    }

    const day = intlUtil.formatDate(utcTimeInMs, SASE_Day_FormatOptions);
    const month = intlUtil.formatDate(utcTimeInMs, SASE_Month_FormatOptions);
    const year = intlUtil.formatDate(utcTimeInMs, SASE_Year_FormatOptions);
    const time = intlUtil.formatTime(utcTimeInMs, SASE_TimeStyleFormatOptions);
    return `${month} ${day}, ${year} ${preposition ?? ""} ${time}`;
}

export function isNil(value: unknown): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return value === undefined || value === null || Number.isNaN(value as any);
}

export function isNilOrEmptyString(value: unknown): boolean {
    return isNil(value) || value === "";
}

export function hasRole(features, role: string): boolean {
    const securityFeature = features?.find(feature => {
        const isFeatureEnabled = feature["feature"] === role;
        return isFeatureEnabled;
    });
    return !isNil(securityFeature);
}

export enum ByteFormats {
    kB,
    MB,
    GB,
    TB,
    PB,
    EB,
    ZB,
    YB
}

export function convertBytes(bytes: number, decimal = 1, conversionFactor = 1024): [string, number] {
    let unitIndex = -1;
    do {
        bytes = bytes / conversionFactor;
        unitIndex++;
    } while (bytes > conversionFactor);
    const converted = Math.max(bytes, 0.1).toFixed(decimal);
    return [converted, unitIndex];
}

export function bytesFormat(bytes: number, conversionFactor = 1024): string {
    if(bytes === 0) {
        return "0 B";
    }
    const result = convertBytes(bytes, 1, conversionFactor);
    return `${result[0]} ${ByteFormats[result[1]]}`;
}

export const isObj = (variable: unknown): boolean => (typeof variable === "object" && variable !== null);

export const isNumber = (value: unknown): boolean => {
    return typeof value === "number" && !isNaN(value) && Infinity > value && -Infinity < value;
}

export function isEmpty (variable: unknown): boolean {
    if (variable === undefined || variable === null) {
        return true;
    }
    if (isObj(variable)) {
        return Object.keys(variable).length === 0;
    }
    if (Array.isArray(variable)) {
        return variable.length === 0;
    }
    if (typeof variable === "string") {
        return variable === "";
    }
    return false;
}

export const allowSubTypeDrilldown = () =>
    isAppTaggingAvailable();

// if filter is present for a node, it would mean the node is zoomed in
export const isNodeZoomed = (nodeType, options) => {
    const { filtersRef } = options;
    const { source, subscription, appType, appSubType } = filtersRef?.current || {};

    switch (nodeType) {
    case SOURCE_NODE_TYPE: {
        return source;
    }
        break;
    case SUBSCRIPTION_NODE_TYPE: {
        return subscription;
    }
        break;
    case APP_TYPE_NODE_TYPE: {
        return appType;
    }
        break;
    case APP_SUB_TYPE_NODE_TYPE: {
        return appSubType;
    }
        break;
    }
}

// This method can be used to create a combined payload with timeRangeFilter
export const createCombinedPayloadWithFilters = (timeRange, data = {}) => {
    const moreRules = [];
    Object.keys(data).forEach((filterKey) => {
        if(data?.[filterKey]) {
            moreRules.push({
                property: filterKey,
                operator: "equals",
                values: [data[filterKey]]
            });
        }
    });
    if(timeRange) {
        moreRules.push({
            property: "event_time",
            operator: "between",
            values: convertTimeRangeToEpoch(timeRange)
        });
    }
    return {
        filter: {
            rules: [
                ...moreRules
            ],
        },
    };
}
