import { SelectItem } from "@panwds/react-ui";
import EnterpriseDLPIcon from "../images/icon_enterprise_dlp.svg";
import DNSSecurityIcon from "../images/icon_dns_security.svg";
import DNSSecurityIconLightMode from "../images/icon_dns_security_light.svg";
import { SubscriptionDetails, TimeRange } from "../redux/threats-server/types";
import { AdemSegmentType } from "../redux/summary-server/types";
import { ActivityInsightsTimeRange, ChartOptions,
    DLPTimeRange,
    NgfwFilterOption } from "../components/NetsecUIChart/NetsecUIChartTypes";
import { constructCustomTimerangeOption } from "../redux/reduxUtils";
import { getBaseName } from "./NetsecUIUtils";

export const AVAILABLE_DASHBOARDS = ["SUMMARY", "THREATS", "OPERATIONAL_HEALTH", "DATA_SECURITY"];

export const DASHBOARDS = {
    THREATS: "THREATS",
    SUMMARY: "SUMMARY",
    OPERATIONAL_HEALTH: "OPERATIONAL_HEALTH",
    DATA_SECURITY: "DATA_SECURITY",
};

export const dashboardDisplayName: Record<string, string> = {
    THREATS: "Threats",
    SUMMARY: "Summary",
    OPERATIONAL_HEALTH: "Operational Health",
    DATA_SECURITY: "Data Security"
}

export const DASHBOARD_INDEXES = {
    SUMMARY: 0,
    THREATS: 1,
    OPERATIONAL_HEALTH: 2,
    DATA_SECURITY: 3
    // TODO add more dashbaords
}

export const DASHBOARDS_POLL_INTERVAL_IN_MS = 5 * 60000; // 300000ms = (5mins * 60000)

export const TIME_ONLY_FORMAT = "HH:mm:ss";

export const TIME_RANGE_OPTIONS: SelectItem[] = [{
    value: "HOURS1",
    children: "Past 1 Hour"
},{
    value: "HOURS3",
    children: "Past 3 Hours"
}, {
    value: "HOURS24",
    children: "Past 24 Hours"
},  {
    value: "DAYS7",
    children: "Past 7 Days"
}, {
    value: "DAYS30",
    children: "Past 30 Days"
}];

export enum ModalTypes {
    "ThreatSubscriptions" = "ThreatSubscriptions",
    "DataSecuritySubscriptions" = "DataSecuritySubscriptions"
}

export const THREAT_REGEX = /Threat/;
export const URL_REGEX = /URL/;
export const WILDFIRE_REGEX = /WildFire/;

export const USER_EXPERIENCE_REGEX = /(device|wifi|lan|internet|app)_score_(good|poor)/;

export const ENABLED_COLORS = {
    Enabled: { appearance: "teal", classes: "badge-teal" },
    Not_Enabled: { appearance: "yellow", classes: "badge-yellow" },
    Not_Applicable: { appearance: "yellow", classes: "badge-yellow" },
    No_License: { appearance: "red", classes: "badge-red" }
}

// source types that can be parents of sub source types
export const parentMap = {
    iot: "IOT DEVICES",
    user: "USERS",
    other: "OTHER",
};

// app types label map to display labels
export const appTypeNodeMap = {
    internet: "INTERNET APPS",
    private_apps: "PRIVATE APPS",
    saas: "SAAS APPS"
};

// app types label map to display labels
export const appSubTypeNodeMap = {
    unsanctioned: "UNSANCTIONED APPS",
    sanctioned: "SANCTIONED APPS",
    tolerated: "TOLERATED APPS"
};

// add more labels if needed
export const AllLabelsMap = {
    USERS: "Users",
    OTHER: "Other",
    IOT_DEVICES: "IOT Devices",
    INTERNET_APPS: "Internet Apps",
    SAAS_APPS: "SaaS Apps",
    PRIVATE_APPS: "Private Apps",
    SANCTIONED_APPS: "Sanctioned Apps",
    UNSANCTIONED_APPS: "Unsanctioned Apps",
    TOLERATED_APPS: "Tolerated Apps",
    OTHER_APPS: "Other Apps",
    isGenAIOnly: "GenAI Apps"
};

export const sourceSubTypeMap = {
    external: "EXTERNAL HOSTS",
    internal: "INTERNAL HOSTS",
    mobile: "REMOTE USERS",
    branch: "BRANCH USERS",
    iot: "IOT DEVICES "
};

export const tooltipSubtitleLabelMap = {
    USERS: "users",
    OTHER: "hosts",
    IOT_DEVICES: "IOT devices",
    REMOTE_USERS: "users",
    BRANCH_USERS: "users",
    INTERNAL_HOSTS: "hosts",
    EXTERNAL_HOSTS: "hosts"
}

// maintain maps to display nodes in the given order
export const sourceNodeOrder = ["IOT DEVICES", "USERS", "OTHER", "REMOTE USERS",
    "BRANCH USERS", "INTERNAL HOSTS", "EXTERNAL HOSTS"];
export const appTypeNodeOrder = ["INTERNET APPS", "SAAS APPS", "PRIVATE APPS"];
export const appSubTypeNodeOrder = ["SANCTIONED APPS", "TOLERATED APPS", "UNSANCTIONED APPS"];
export const subscriptionNodeOrder = ["Threat Prevention", "URL Filtering", "WildFire", "DNS Security"];
export const platformTypeNodeOrder = ["NGFW", "Prisma Access"];

export const platformTypeMap = {
    all: "All",
    ngfw: "NGFW",
    prisma_access: "Prisma Access"
}

export const platformTypeLicenseMap = {
    ngfw: "panw_netsec:ngfw_enabled",
    prisma_access: "panw_netsec:prisma_access_enabled"
}

// ADEM License
export type ademLicenseContentTypes = "userExperienceForSummary" | "userExperienceForOperationalHealth" | "ademUserInfoForOperationalHealth"
export const ademLicenseMap = {
    userExperienceForSummary: "panw_netsec:summary_user_experience",
    userExperienceForOperationalHealth: "panw_netsec:operational_health_user_experience",
    ademUserInfoForOperationalHealth: "panw_netsec:operational_health_prisma_access_monitoring"
}

export const SEVERITY_LEVELS = ["Critical", "High", "Medium", "Low"];

export const RECORD_DURATIONS = true;

export const CHART_REDIRECT_ROUTES = () => ({
    ACTIVITY_INSIGHTS_APP_DETAILS_ROUTE: `${getBaseName()}/insights/activity_insights_app/apps/details`,
    ACTIVITY_INSIGHTS_APP_EXPERIENCE_ROUTE: `${getBaseName()}/insights/activity_insights_app/apps/details/experience`,
    ACTIVITY_INSIGHTS_APPS_LIST_ROUTE: `${getBaseName()}/insights/activity_insights/apps`,
    ACTIVITY_INSIGHTS_APPS_USERS_ROUTE: `${getBaseName()}/insights/activity_insights/users`,
    ACTIVITY_INSIGHTS_THREATS_ROUTE: `${getBaseName()}/insights/activity_insights/threats`,
    INCIDENTS_ALERTS_NGFW_ROUTE: `${getBaseName()}/incidents-alerts/ngfw/allalerts`,
    INCIDENTS_ALERTS_PRISMA_ACCESS_ROUTE: `${getBaseName()}/incidents-alerts/prisma-access/incidents-alerts`,
    ACTIVITY_INSIGHTS_APPS_MANAGE_APP_ROUTE: `${getBaseName()}/insights/activity_insights_app/apps/manage-application`,
    APPLICATION_EXPERIENCE_ROUTE: `${getBaseName()}/dashboards/application-experience`,
    MANAGE_DATA_LOSS_PREVENTION: `${getBaseName()}/casb/data-loss-prevention`,
    MANAGE_SAAS_SECURITY: `${getBaseName()}/casb/saas-security`,
    MANAGE_DATA_PATTERNS: `${getBaseName()}/casb/saas-security/api-settings/data-patterns`,
    MANAGE_DATA_LOSS_PREVENTION_DATA_PROFILES: `${getBaseName()}/casb/data-loss-prevention/data-profiles`,
    DEVICE_HEALTH_DASHBOARD: `${getBaseName()}/dashboards/health`,
    SASE_HEALTH_DASHBOARD: `${getBaseName()}/dashboards/sase-health`
})

export const SUBSCRIPTION_ACTION_PERFORMED = {
    Threat_Prevention: "Threats Blocked",
    URL_Filtering: "Malicious URLs Detected",
    WildFire: "Malicious Verdicts",
    DNS_Security: "Malicious DNS Requests",
    inline_dlp: "critical incidents",
    saas_api: "critical incidents",
    saas_inline: "high risk apps detected",
    posture_security: "critical violations",
    email_dlp: "critical incidents",
    ai_access: "critical DLP incidents",
    ng_casb: " "
}

export const SUBSCRIPTION_ITEMS = {
    Threat_Prevention: "threats",
    URL_Filtering: "malicious URLs",
    WildFire: "malicious verdicts",
    DNS_Security: "malicious DNS requests"
}

export const SUBSCRIPTION_DRILLDOWN_TOTAL = {
    Threat_Prevention: "Traffic Analyzed",
    URL_Filtering: "URLs Analyzed",
    WildFire: "Files Submitted",
    DNS_Security: "DNS Requests"
}

export const SUBSCRIPTION_DRILLDOWN_MALICIOUS = {
    Threat_Prevention: "Threats Detected",
    URL_Filtering: "Malicious URLs",
    WildFire: "Malicious Verdicts",
    DNS_Security: "Malicious DNS Requests"
}

export const SECURITY_SERVICE_TYPE = {
    threat_protection: "Threat Prevention",
    dns_security: "DNS Security",
    url_filtering: "URL Filtering",
    wildfire: "WildFire",
}

export const PEER_DATA_INDUSTRY_KEY_MAP = {
    malicious_threats: "Threat_Prevention",
    malicious_dns: "DNS_Security",
    malicious_url: "URL_Filtering",
    malicious_wildfire: "WildFire"
}

export const SUBSCRIPTION_DESCRIPTIONS: Record<string, SubscriptionDetails> = {
    Threat_Prevention: {
        title: "Enable Threat Prevention",
        description: "Gain real-time protection against exploits, malware, spyware and unknown C2 attacks by utilizing industry-first inline deep learning models and top-grade signatures to safeguard your network.",
        // TODO update value
        peerDescription:
            "Your industry peers on average prevented {numberOfRequestsPrevented} threats with Threat Prevention enabled.",
        learnMoreLink: "https://www.paloaltonetworks.com/network-security/advanced-threat-prevention",
        buttonText: "Enable Threat Prevention",
        // TODO Replace icon
        imageSvg: DNSSecurityIcon,
        imageSvgLight: DNSSecurityIconLightMode,
        configureLink: "https://docs.paloaltonetworks.com/advanced-threat-prevention/administration/configure-threat-prevention",
        enableLink: "/manage/security-services/anti-spyware?container=All&containerType=container"
    },
    URL_Filtering: {
        title: "Enable URL Filtering",
        description: "Defend against evolving web-based threats by utilizing advanced URL filtering to detect and prevent malicious URLs.",
        peerDescription:
            "Your industry peers on average prevented {numberOfRequestsPrevented} malicious URLs with URL Filtering enabled.",
        learnMoreLink: "https://www.paloaltonetworks.com/network-security/advanced-url-filtering",
        buttonText: "Enable URL Filtering",
        // TODO Replace icon
        imageSvg: DNSSecurityIcon,
        imageSvgLight: DNSSecurityIconLightMode,
        configureLink: "https://docs.paloaltonetworks.com/advanced-url-filtering/administration/configuring-url-filtering/configure-url-filtering",
        enableLink: "/manage/security-services/url-access-management?container=All&containerType=container"
    },
    WildFire: {
        title: "Enable Wildfire",
        description: "Stay ahead of evolving threats with WildFire®. Our cloud-based malware detection, coupled with inline ML modules on the next-gen firewall, automatically identifies and thwarts new and unknown threats in real-time.",
        peerDescription:
            "Your industry peers on average prevented {numberOfRequestsPrevented} malicious verdicts with Wildfire enabled.",
        learnMoreLink: "https://www.paloaltonetworks.com/network-security/advanced-wildfire",
        buttonText: "Enable Wildfire",
        // TODO Replace icon
        imageSvg: DNSSecurityIcon,
        imageSvgLight: DNSSecurityIconLightMode,
        configureLink: "https://docs.paloaltonetworks.com/advanced-wildfire/wildfire-appliance/set-up-and-manage-a-wildfire-appliance/configure-the-wildfire-appliance",
        enableLink: "/manage/security-services/wildfire-and-antivirus?container=All&containerType=container"
    },
    DNS_Security: {
        title: "Enable DNS Security",
        description: "Stop attackers from abusing DNS for malicious activities like data theft, C2, phishing, and ransomware.",
        peerDescription:
            "Your industry peers on average prevented {numberOfRequestsPrevented} malicious DNS requests with DNS Security enabled.",
        learnMoreLink: "https://www.paloaltonetworks.com/network-security/dns-security",
        buttonText: "Enable DNS Security Profiles",
        imageSvg: DNSSecurityIcon,
        imageSvgLight: DNSSecurityIconLightMode,
        configureLink: "https://www.paloaltonetworks.com/network-security/dns-security",
        enableLink: "/manage/security-services/dns-security?container=All&containerType=container"
    },
    Enterprise_DLP: {
        title: "Try Enterprise DLP",
        description:
            "Start an eval to safeguard sensitive data across all users, clouds, and networks.",
        peerDescription:
            "On average, Enterprise DLP protected {numberOfRequestsPrevented} files and {size} of data for organizations like yours.",
        buttonText: "Start Eval",
        actionItem: "Contact your Sales Representative for more details.",
        imageSvg: EnterpriseDLPIcon,
        imageSvgLight: EnterpriseDLPIcon, // change to light mode icon
        learnMoreLink: "https://www.paloaltonetworks.com/network-security/enterprise-data-loss-prevention",
        // TODO replace when subscription is enabled
        configureLink: "https://docs.paloaltonetworks.com/dns-security/administration/configure-dns-security",
        enableLink: "/manage/security-services/vulnerability-protection?container=All&containerType=container"
    }
}

export const LINK_COLORS = {
    THREATS: ["Medium", "High", "Low", "Critical"],
    SUMMARY: ["TRAFFIC"],
    OPERATIONAL_HEALTH: ["good", "poor", "notMonitored"],
    DATA_SECURITY: ["Medium", "High", "Low", "Critical"],
}

export const THREAT_LINK_TOOLTIP_COLUMNS = [
    { category: "Severity" },
    { category: "Blocked" },
    { category: "Alerted" }
]

export const DATA_SECURITY_DATA_IN_MOTION_LINK_TOOLTIP_COLUMNS = [
    { category: "Data Risk" },
    { category: "Blocked" },
    { category: "Alerted" }
]

export const DATA_SECURITY_DATA_AT_REST_LINK_TOOLTIP_COLUMNS = [
    { category: "Data Risk" },
    { category: "Quarantined" },
    { category: "Sharing Revoked" }
]

export const THREAT_APP_TOOLTIP_COLUMNS = [
    { category: "Severity" },
    { category: "Threats" }
]

export const DS_TOP_PROFILE_ACTIONS_MAP = (intlInstance) => ({
    allowed: intlInstance.formatMessage({
        id: "commandCenter.dataSecurity.topDataProfilesByAction.AlertedLabel",
        defaultMessage: "Alerted"
    }),
    quarantined: intlInstance.formatMessage({
        id: "commandCenter.dataSecurity.topDataProfilesByAction.QuarantinedLabel",
        defaultMessage: "Quarantined"
    }),
    sharing_revoked: intlInstance.formatMessage({
        id: "commandCenter.dataSecurity.topDataProfilesByAction.SharingRevokedLabel",
        defaultMessage: "Sharing Revoked" }),
    blocked: intlInstance.formatMessage({
        id: "commandCenter.dataSecurity.topDataProfilesByAction.BlockedLabel",
        defaultMessage: "Blocked" }),
});

export const ADEM_USER_EXPERIENCE_DISPLAY_NAME_MAP: Record<string, AdemSegmentType> = {
    device: "Device",
    wifi: "WiFi",
    lan: "LAN",
    internet: "Internet",
    app: "Application"
}

export const ALL = "all";
export const YES = "yes";

export const NGFW = "ngfw";

export const PRISMA_ACCESS = "prisma_access";
export const THREAT_PREVENTION = "Threat Prevention";
export const WILDFIRE = "WildFire";
export const DNS_SECURITY = "DNS Security";
export const URL_FILTERING = "URL Filtering";

export const DATA_SECURITY_SOURCE_NODE_LABELS = {
    USERS: "SENSITIVE DATA USERS",
}

export const DATA_SECURITY_SUBSCRIPTION_NODES = {
    DATA_IN_MOTION: "data_in_motion",
    DATA_AT_REST: "data_at_rest",
}

export const DATA_SECURITY_SUBSCRIPTION_NODE_LABELS = {
    data_in_motion: "Data in Motion",
    data_at_rest: "Data at Rest",
}

export const DATA_SECURITY_APPTYPE_NODE_LABELS = {
    tolerated: "TOLERATED APPS",
    unsanctioned: "UNSANCTIONED APPS",
    sanctioned: "SANCTIONED APPS",
    unknown: "OTHER APPS"
}

export const DATA_SECURITY_SEVERITY_MAP = {
    critical: "Critical",
    very_high: "Critical",
    high: "High",
    medium: "Medium",
    low: "Low",
}

export const THREAT_SEVERITY_MAP = {
    critical: "Critical",
    high: "High",
    medium: "Medium",
    low: "Low",
}

export const DATA_SECURITY_SUBSCRIPTION_DISPLAY_NAMES = {
    "inline_dlp": "DLP Inline",
    "saas_inline": "SaaS Inline",
    "saas_api": "SaaS API",
    "sspm": "SSPM",
    "posture_security": "Posture Security",
    "email_dlp": "Email DLP",
    "ai_access": "AI Access",
    "ng_casb": "NG CASB"
}

export const DATA_SECURITY_APP_DISPLAY_LABELS = {
    "email_dlp": {
        "app_risk": "App Risk",
        "total_users": "Users",
        "total_allowed_assets": "Allowed Email",
        "total_blocked_assets": "Blocked Email",
        "total_quarantined_assets": "Quarantined Email",
        "total_encrypted_assets": "Encrypted Email",
    },
    "saas_api": {
        "data_security": "Data Security",
        "total_scanned_assets": "Sensitive Assets",
        "total_public_assets": "Public Exposure",
        "total_external_assets": "External Exposure",
        "total_quarantined_assets": "Quarantined Assets",
        "total_data_profile_matches": "Data Profile Matches",
        "total_sharing_revoked_assets": "Sharing Revoked",
    },
    "sspm": {
        "posture_security": "Posture Security",
        "total_violations": "Violations",
    },
    "inline_dlp": {
        "tag": "Tag",
        "app_risk": "App Risk",
        "total_uploaded_assets": "Uploaded Assets",
        "total_downloaded_assets": "Downloaded Assets",
        "total_blocked_assets": "Blocked Assets",
        "total_allowed_assets": "Alerted Assets",
    },
}

export const DATA_SECURITY_APP_REDIRECT_ROUTES = () => ({
    total_users_accessing: `${getBaseName()}/casb/saas-security/user-activities/?tab=user-activities`,
    total_scanned_assets: `${getBaseName()}/casb/saas-security/assets/?tab=assets`,
    total_public_assets: `${getBaseName()}/casb/saas-security/assets/?tab=assets`,
    total_external_assets: `${getBaseName()}/casb/saas-security/assets/?tab=assets`,
    total_data_profile_matches: `${getBaseName()}/casb/saas-security/assets/?tab=assets`,
    total_sharing_revoked_assets: `${getBaseName()}/casb/saas-security/assets/?tab=assets`,
    total_quarantined_assets: `${getBaseName()}/casb/saas-security/assets/?tab=quarantine`,
    total_violations: `${getBaseName()}/casb/saas-security/posture-security/dashboard`,
    total_total_users: `${getBaseName()}/casb/saas-security/discovered-apps`,
    total_uploaded_assets: `${getBaseName()}/casb/data-loss-prevention/incidents`,
    total_downloaded_assets: `${getBaseName()}/casb/data-loss-prevention/incidents`,
    total_blocked_assets: `${getBaseName()}/casb/data-loss-prevention/incidents`,
});

export const DATA_SECURITY_SEVERITY_VERY_HIGH = "very_high";
export const DATA_SECURITY_SEVERITY_CRITICAL = "critical";

export const DATA_SECURITY_SUBSCRIPTION_SUB_TEXT_DISPLAY_NAMES = {
    allowed: "Alerted",
    blocked: "Blocked",
    sharing_revoked: "Sharing Revoked",
    quarantined: "Quarantined",
    exposed: "Exposed",
    public: "Public",
    external: "External"
}

// TODO confirm colors
export const DATA_SECURITY_COLOR_CLASSNAME_MAP = {
    high: "cc-border-[#6C0F0F] cc-text-[#e14e4e]",
    low: "cc-border-[#453703] cc-text-yellow",
    medium: "cc-border-orange cc-text-orange",
    critical: "cc-border-[#b82e93] cc-text-[#b82e93]"
}

export const OPERATIONAL_HEALTH_EXPERIENCE_COLOR_MAP = {
    poor: "cc-border-[#E14E4E]",
    fair: "cc-border-[#F38C16]",
    good: "cc-border-[#33CCB8]",
};

export const ALL_CHANNELS = "all_channels";
export const DATA_IN_MOTION = "data_in_motion";
export const DATA_AT_REST = "data_at_rest";
export const SUMMARY = "summary";
export const BLOCKED = "blocked";
export const ACTIVE = "active";

export const NGFW_TIMERANGE_MAPPINGS: Record<TimeRange | "ALL", () => NgfwFilterOption> = {
    DAYS30: () => ({
        label: "Past 30 Days", value: "30"
    }),
    DAYS7: () => ({
        label: "Past 7 Days", value: "7"
    }),
    HOURS24: () => ({
        label: "Past 24 Hours", value: "24"
    }),
    HOURS1: () =>
        constructCustomTimerangeOption((m) => m.subtract("1", "hours")),
    HOURS3: () => constructCustomTimerangeOption((m) => m.subtract("3", "hours")),
    ALL: () => ({
        label: "All", value: "all"
    }),
}

export const NGFW_SEVERITIES = {
    Critical: "Critical",
    Warning: "Warning",
    Info: "Info"
}

export const SWITCH_TO = "SWITCH TO";
export const ACTIVITY_INSIGHTS_TIMERANGE_MAPPINGS: Record<TimeRange, ActivityInsightsTimeRange> = {
    DAYS30: "30d",
    DAYS7: "7d",
    HOURS24: "24h",
    HOURS1: "1h",
    HOURS3: "3h"
}

export const DLP_TIMERANGE_MAPPINGS: Record<TimeRange, DLPTimeRange> = {
    DAYS30: "past_30_days",
    DAYS7: "past_7_days",
    HOURS24: "past_24_hours",
    HOURS1: "past_60_minutes",
    HOURS3: "past_3_hours"
}

export const IncidentPageType = {
    LIST_VIEW: "Listview",
    OVERVIEW: "Overview"
}

export const noADEMLicenseAppExperienceForPA =
    `Simplify IT operations by providing end-to-end performance visibility for all
    users and branches within your organization and reduce MTTR by isolating problematic segments.`;

export const notEnabledADEMAppExperienceForSubscription =
    "Complete the ADEM enablement process by monitoring users or sites.";

// filter map for threats
export const ThreatMapping = {
    sourceName: "una_source_type",
    subscriptionSummaryName: "una_scope_selection",
    subscriptionThreatName: "una_threat_license",
    appTypeName: "una_application_type",
    trafficProcessedBy: "una_scope_selection",
    threatCategoryName: "una_threat_category",
    timeRange: "timeInterval"
}

export const ThreatAppTypeValueMapping = {
    "SAAS APPS": "saas",
    "PRIVATE APPS": "private",
    "INTERNET APPS": "internet"
}

export const AppMappingForActivityInsights = {
    trafficProcessedBy: "una_scope_selection",
    appTypeName : "applicationType",
    timeRange: "timeInterval",
    subscriptionName: "una_scope_selection",
    sourceName: "sourceType",
    securityServiceType: "securityServiceType",
    appSubTypeName: "una_app_tags",
    isGenAIOnly: "una_gen_ai"
}

export const SegmentMappingForActivityInsights = {
    Device: "deviceExperienceScore",
    WiFi : "wlanExperienceScore",
    LAN: "lanExperienceScore",
    Internet: "internetExperienceScore",
    Application: "experienceScore",
}

export const AppTypeValueMappingForActivityInsights = {
    "SAAS APPS": "SaaS Apps",
    "PRIVATE APPS": "Private Apps",
    "INTERNET APPS": "Internet Apps"
}

export const AppSubTypeValueMappingForActivityInsights = {
    "SANCTIONED APPS": "sanctioned",
    "UNSANCTIONED APPS": "unsanctioned",
    "TOLERATED APPS": "tolerated"
}

export const SourceValueMappingForActivityInsights = {
    USERS: "Users",
    OTHER: "Other",
    "IOT DEVICES": "IOT Devices"
};

export const NGFWDeviceHealthScoreToGradeMapping = {
    Good: {
        min: 91,
        max: 100
    },
    Fair: {
        min: 81,
        max: 90
    },
    Poor: {
        min: 66,
        max: 80
    },
    Critical: {
        min: 0,
        max: 65
    }
}

export const DefaultProfile = "Default Data Patterns";

export const subscriptionNodeTooltipText = {
    DATA_IN_MOTION: "This represents data flowing through network inspected via DLP inline as well as outbound emails inspected via Email DLP.",
    DATA_AT_REST: "This represents the data residing in connected SaaS applications inspected via SaaS API."
}

export const BYTES_CONVERSION_FACTOR = 1000;
export const MOUSE_LEAVE_TIMEOUT = 1000;
export const HOVER_CARD_ELEMENT = "#hover-card";

export const THREAT_ACTIONS = {
    Blocked: "Blocked",
    Alerted: "Alerted"
}

export const ASPECT_RATIO = 21/9;
export const NETSEC_CHART_MIN_WIDTH = 1340;
export const NETSEC_CHART_MIN_HEIGHT = 575;

export const SCREEN_WIDTH_L = 1440
export const SCREEN_WIDTH_XL = 1920
export const SCREEN_WIDTH_XXL = 2560
export const SCREEN_WIDTH_XXXL = 3840

// aspect ration by width breakpoints
export const AspectRatioDefinition = {
    SCREEN_WIDTH_STANDARD: ASPECT_RATIO,
    SCREEN_WIDTH_L: ASPECT_RATIO * 1.18, // <= 1440
    SCREEN_WIDTH_XL: ASPECT_RATIO * 1.20, // <= 1920
    SCREEN_WIDTH_XXL: ASPECT_RATIO * 1.60, // <= 2560
    SCREEN_WIDTH_XXXL: ASPECT_RATIO * 2.40 // <= 3840
};

export const OP_HEALTH_LINK_COLOR_ORDER = ["good", "poor", "notMonitored"];
export const THREAT_LINK_COLOR_ORDER = ["Critical", "High","Medium", "Low"];
export const NO_LICENSE = "no_license";
export const NOT_APPLICABLE = "not_applicable";
export const NO_PERMISSION = "no_permission";